
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck,faHandshake, faLightbulb,faMap, faChartLine, } from '@fortawesome/free-solid-svg-icons';
import React,{useEffect}from 'react'
import './services.css'
import ctoS from "../../Assets/ctoService.png"
const CtoService=()=> {

    useEffect(() => {
        window.scrollTo(0, 0);
    
      }, []);
    return (
        <div  className='our-service'>
            
            <div className="container-fluid mt-5 ">
                <div className="container py-5">
                <h1 className=" text-uppercase our-service-heading">CTO as a Service</h1>
                    <div className="row g-5 mb-5">
                        <div className="col-xl-6">
                            <div className="row g-4">
                                <div className="col-12">
                                    <img src={ctoS} className="img-fluid pb-3 wow zoomIn " data-wow-delay="0.1s" alt="cto" />
                                    
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 wow fadeIn" data-wow-delay="0.5s">
                            <p className="pb-4">Our CTO as a service helps startups when they have an idea for
                                product but don’t have enough technical expertise to choose the right technologies and a team.</p>
                            <div className="row g-4 mb-4">
                                <div className="sectioner-header text-center">
                                    <h3 className='our-service-subheading'>Features and Offerings</h3>
                                </div>
                                <div className="col-md-6">
                                    <div className="ps-3 d-flex align-items-center justify-content-start">
                                        <span className="">
                                            <FontAwesomeIcon icon={faLightbulb} size='3x' className='our-service-icon'/>
                                        </span>
                                        <div className="ms-4">
                                            <hh6 className='our-service-subheading'>MVP Idea to Investors</hh6>
                                            <p>Turn your idea into an MVP and want to represent it to investors</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="ps-3 d-flex align-items-center justify-content-start">
                                        <span className="">
                                            <FontAwesomeIcon icon={faHandshake} size='3x' className='our-service-icon' />
                                        </span>
                                        <div className="ms-4">
                                            <h6 className='our-service-subheading'>Full Time CTO</h6>
                                            <p>When start ups can not afford full time CTO</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row g-4 mb-4">
                                <div className="col-md-6">
                                    <div className="ps-3 d-flex align-items-center justify-content-start">
                                        <span className="">
                                            <FontAwesomeIcon icon={faMap} size='3x' className='our-service-icon'/>
                                        </span>
                                        <div className="ms-4">
                                            <h6 className='our-service-subheading'>Strategize IT Roadmap</h6>
                                            <p>Strategize the IT roadmap without an IT</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="ps-3 d-flex align-items-center justify-content-start">
                                        <span className="">
                                            <FontAwesomeIcon icon={faChartLine} size='3x' className='our-service-icon'/>
                                        </span>
                                        <div className="ms-4">
                                            <h6 className='our-service-subheading'>Business Scale</h6>
                                            <p>Business is ready to scale and needs the right tech decisions for this scale</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row g-2">
                            <div className="sectioner-header text-center">
                                    <h3 className='our-service-subheading'>Benefits</h3>
                                </div>
                                <div className="col-md-4">
                                    <p className="mb-2"><FontAwesomeIcon icon={faCheck} className='our-service-icon px-4' />Security </p>
                                    <p className="mb-0"><FontAwesomeIcon icon={faCheck} className='our-service-icon px-4' />Budget </p>
                                </div>
                                <div className="col-md-4">
                                    <p className="mb-2"><FontAwesomeIcon icon={faCheck} className='our-service-icon px-4' />Piece of Mind</p>
                                    <p className="mb-0"><FontAwesomeIcon icon={faCheck} className='our-service-icon px-4' />Cost Effective</p>
                                </div>
                                <div className="col-md-4">
                                    <p className="mb-2"><FontAwesomeIcon icon={faCheck} className='our-service-icon px-4' />Compliance</p>
                                    <p className="mb-0"><FontAwesomeIcon icon={faCheck} className='our-service-icon px-4' />Timelines</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CtoService
