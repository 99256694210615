import React from 'react';
import Loader from '../../Assets/mainLoading1.gif';
import "./loader.css";
 
function LoadingScreen() {
  return (
        <div className='loader'>
        <img src={Loader} alt='Loading'/>
    </div>
  )
}
 
export default LoadingScreen