import React,{useEffect} from 'react'
import './privacy.css'

export default function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
     <div className="privacy-policy">
      <h1>Privacy Policy for  <span>Comptivia </span></h1>
      <p>Last Updated: 1/24/2024</p>

      <p>
        Thank you for visiting Comptivia ! Your privacy is important to us, and we want you to feel comfortable using our website. This Privacy Policy outlines the types of personal information we receive and collect when you use our website, as well as some of the steps we take to safeguard information. By using our website, you agree to the terms of this Privacy Policy.
      </p>

      <h2>Information We Collect</h2>

      <p>
        <strong>Google Analytics:</strong> We use Google Analytics to analyze the use of our website. Google Analytics gathers information about website use through cookies. The information collected is used to create reports about the use of our website. Google's privacy policy is available at <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Google Privacy & Terms</a>.
      </p>

      <h2>Cookies</h2>

      <p>
        <strong>Google Analytics Cookies:</strong> We use cookies provided by Google Analytics to analyze the use of our website. These cookies are used to collect information about how visitors use our site. You can opt-out of these cookies by using the <a href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noopener noreferrer">Google Analytics Opt-out Browser Add-on</a>.
      </p>

      <h2>Information Security</h2>

      <p>
        <strong>Data Security:</strong> We implement security measures to protect against unauthorized access to or unauthorized alteration, disclosure, or destruction of data.
      </p>

      <h2>Your Choices</h2>

      <p>
        <strong>Cookies:</strong> Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. This may prevent you from taking full advantage of the website.
      </p>

      <h2>Changes to this Privacy Policy</h2>

      <p>
        <strong>Updates:</strong> We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
      </p>

      <h2>Contact Us</h2>

      <p>If you have any questions about this Privacy Policy, please contact us at <a href="mailto:info@comptivia.com">info@comptivia.com</a></p>

    </div>
  );
};
 