
import React, { useState, useEffect } from 'react'
import './contact.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import worldmap from '../../Assets/worldmap.svg'
import { toast } from 'react-toastify';
import { faMapMarkerAlt, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
const Contact = () => {

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    project: '',
    message: '',
  });


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };


  

 const handleSendMessage = async () => {
  // Validate form data
  if (!formData.name || !formData.email || !formData.project || !formData.message) {
    toast.error('Please fill in all required fields.');
    return; // Stop further execution
  }
 
  try {
    const response = await axios.post('https://api.fmt.comptivia.com/enginuo/api/v1/sendMail', formData, {
      // headers: {
      //   'Content-Type': 'application/json',
      // },
    });
    if (response.status === 200){
      console.log(response)
      toast.success('We will contact you soon');
   }
 
    // Handle the response as needed
  } catch (error) {
    // Handle errors
    console.error('Error:', error);
  }
 
  // Reset form data after successful submission
  setFormData({
    name: '',
    email: '',
    project: '',
    message: '',
  });
};


  return (

    <div className="container-fluid py-5 mb-5 mt-4">
      <div className="container-fluid">
        <div className="contact-detail position-relative p-5">
          <div className="text-center mx-auto pb-5 wow fadeIn" data-wow-delay=".3s" style={{ maxWidth: '600px' }}>

            <h5 className="text-primary">Get In Touch</h5>
            <h1 className="mb-3">Contact for any query</h1>
            <p className="fs-5">
              How can comptivia help you? Please provide the following
              information so that we can process your request most efficiently. A member of our team will contact you shortly
            </p>
          </div>

        <div className="row g-4">
  {[
    { icon: faMapMarkerAlt, title: 'Address', content: 'Montville NJ 07045' },
    { icon: faPhone, title: 'Call Us', content: '+1 973 944 1209' },
    { icon: faEnvelope, title: 'Email Us', content: 'info@comptivia.com' },
  ].map((item, index) => (
    <div key={index} className="col-md-4 mb-4">
      <div className="bg-dark p-4 rounded text-center">
        <div className="flex-shrink-0 btn-square rounded-circle mx-auto mb-3" style={{ width: '64px', height: '64px', backgroundColor: '#9A0412' }}>
          <FontAwesomeIcon icon={item.icon} className="text-white contact-icon" />
        </div>
        <h4 className="text-primary mb-2">{item.title}</h4>
        <p className="h5" rel="noopener noreferrer" style={{ maxWidth: '100%', margin: '0 auto', wordWrap: 'break-word' }}>
          {item.content}
        </p>
      </div>
    </div>
  ))}
        </div>

          <div className="row g-5">
            <div className="col-lg-6 wow fadeIn" data-wow-delay=".3s">
              <div className=" h-100 rounded contact-map">
                <img
                  className="rounded w-100 h-100"
                  src={worldmap}
                  style={{ border: '0' }}
                  allowFullScreen=""
                  loading="lazy"
                ></img>
              </div>

            </div>

            <div className="col-lg-6 wow fadeIn" data-wow-delay=".5s">
              <div className="p-3 rounded contact-form" >
                <div className="mb-4">
                  <input type="text" name="name" value={formData.name}
                    onChange={handleChange} className="form-control border-0 py-3" placeholder="Your Name"
                    />
                </div>
                <div className="mb-4">
                  <input type="email" name="email" value={formData.email}
                    onChange={handleChange} className="form-control border-0 py-3" placeholder="Your Email"
                  />
                </div>
                <div className="mb-4">
                  <input type="text" name="project" value={formData.project}
                    onChange={handleChange} className="form-control border-0 py-3" placeholder="Project"
                  />
                </div>
                <div className="mb-4">
                  <textarea name="message" value={formData.message}
                    onChange={handleChange} className="w-100 form-control border-0 py-3" rows="6" cols="10" placeholder="Message"
                  ></textarea>
                </div>
                <div className="text-start">
                  <button className="btn bg-primary text-white py-3 px-5" type="button" onClick={handleSendMessage} >
                    Send Message
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center mx-auto pb-5 wow fadeIn" data-wow-delay=".3s" style={{ maxWidth: '600px' }}>
 
 
        <h1 className="mb-3">Our <span style={{color:'#C11907'}}>collaboration</span></h1>
 
        <img src="https://www.designrush.com/topbest/images/svg/designrush-new-logo.svg" alt="DesignRush" width="296" height="67" style={{ margin: 'auto' }} data-pin-nopin="true" />
        <button className="btn text-white " type="button" style={{backgroundColor:'#C11907'}}  onClick={()=>window.open('https://www.designrush.com/', '_blank')}>
          Click here
        </button>
      </div>
    </div>

  )
}

export default Contact