import React,{useEffect} from 'react'
import './terms.css'

export default function Terms() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
  <div className="containerT">
            <h1>Terms of Use</h1>
            <p>Last Updated: 1/24/2024</p>

            <p>Welcome to Comptivia Technologies! By accessing and using this website, you agree to comply with and be bound by the following terms and conditions of use. If you do not agree with any part of these terms, please do not use our website.</p>

            <h2>Use of the Website</h2>
            <p>1. <strong>Acceptance of Terms:</strong> The use of this website is subject to the terms of use as outlined herein. Your use of the website constitutes acceptance of these terms.</p>
            <p>2. <strong>Age Requirement:</strong> You must be at least 18 years old to use this website. By using this website, you represent that you meet this age requirement.</p>

            <h2>Intellectual Property</h2>
            <p>1. <strong>Ownership:</strong> The content on this website, including but not limited to text, graphics, logos, images, and software, is the property of Comptivia and is protected by copyright and other intellectual property laws.</p>
            <p>2. <strong>Restrictions:</strong> You may not modify, reproduce, distribute, create derivative works, publicly display, or otherwise use any content from this website without the prior written consent of Comptivia.</p>

            <h2>Disclaimer</h2>
            <p>The information provided on this website is for general informational purposes only. Comptivia makes no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose.</p>

            <h2>Contact Us</h2>
            <p>If you have any questions about these Terms of Use, please contact us at <a href="mailto:info@comptivia.com">info@comptivia.com</a>.</p>
        </div>
  )
}
