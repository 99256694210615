import React, { useState } from "react";
import "./deliveryModel.css";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import podModel from "../../Assets/podModel.svg";


const DeliveryModel = () => {



  return (
    <Container style={{ backgroundColor: "#161617" }}>
      <h1 className="hr-lines">
        Concord <span id="span">Delivery Model </span>{" "}
      </h1>

      <Row className="carde">
        {/* <h4> Redefining Value Delivery With Product Centric Development</h4> */}
        <img src={podModel} alt="podModel" style={{ marginLeft: 20 }} />
      </Row>

      {/* why make us unique */}

      <h1 className="hr-lines">
        What make us <span id="span">unique</span> ?
      </h1>

      <Row className="carde">
        <Col md={4}>
          <div >
            <Card style={{ border: "none", backgroundColor: "#9A0412", marginBottom:"8%" }} className="selected ">
              <Card.Img variant="top" src={require("../../Assets/card1.png")} className="p-3" />
              <Card.Body>
                <Card.Text>
                  As a digital native company, we deliver consulting and
                  software services with agility, flexibility and expertise. We
                  have highly trained pool of talent that provides world class
                  business solutions
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </Col>

        <Col md={4}>
          <div  >
            <Card style={{ border: "none", backgroundColor: "#9A0412" ,marginBottom:"8%" }} className="selected  ">
              <Card.Img variant="top" src={require("../../Assets/card2.png")} className="p-3" />
              <Card.Body>
                <Card.Text>
                  We are true partners, not vendors. We value our customers as true partners and leverage our extensive vertical
                  industry experience to deliver tailored solutions that align with their unique needs and goals
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </Col>

        <Col md={4} >
          <div  >
            <Card style={{ border: "none", backgroundColor: "#9A0412" }} className="selected ">
              <Card.Img variant="top" src={require("../../Assets/card3.png")} className="p-3" />
              <Card.Body>
                <Card.Text>
                  We have a highly trained pool of talent that provides world-class business solutions, both offshore and onsite. Our commitment to service excellence ensures a unique employee journey and an A+ work environment {" "}
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </Col>
      </Row>

      {/* why comptivia  */}
      <div className="container-fluid py-5 ">
        <div className="container py-5">
          <div className="row g-5">
          
            <div className="col-lg-5 col-md-6 col-sm-12 wow fadeIn " data-wow-delay=".3s">
              <div className="h-100 position-relative">
                <img src="https://img.freepik.com/free-photo/html-system-websites-concept_23-2150323528.jpg?size=626&ext=jpg" className="img-fluid w-75 rounded" alt="" style={{ marginBottom: '25%' }} />
                <div className="position-absolute w-75" style={{ top: '25%', left: '25%' }}>
                  <img src="https://img.freepik.com/free-photo/html-system-website-concept_23-2150376770.jpg?size=626&ext=jpg" className="img-fluid w-100 rounded" alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-6 col-sm-12 wow fadeIn" data-wow-delay=".5s">
              <h1 className="mb-4 text-bold">Why <span id="span">Comptivia</span> ?</h1>
              <p>The core mission is to give every business its self-delivering arm of technology expertise. 
          We provide solutions for the challenges in companies to have IT teams that need skills for 
          the new technology emerging market, To Drive advantages that’s centered around technologies such as AI, Data, Cloud, etc</p>
              <a href="" className="btn btn-secondary rounded-pill px-5 py-3 text-white">More Details</a>
            </div>
            
          </div>
        </div>
      </div>

    </Container>
  );
};
export default DeliveryModel;