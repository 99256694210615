import React from "react";
import {
  Navbar,
  Collapse,
  Typography,
  IconButton,
  List,
  ListItem,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
} from "@material-tailwind/react";
import {
  ChevronDownIcon,
  Bars3Icon,
  XMarkIcon,
  ShieldCheckIcon,
  CodeBracketIcon,
  ServerIcon,
  CloudArrowDownIcon,
 
} from "@heroicons/react/24/outline";
import {
  CloudIcon, UserIcon,
 
} from "@heroicons/react/24/solid";
import logo from '../../../Assets/comptivia.png';
import { Link } from 'react-router-dom';
import './header.css';
 
 
//List of items in Services Menu
const navListMenuItems = [
  {
    title: "CTO As a Service",
    description: "Tech leadership on-demand for strategic optimization.",
    icon: UserIcon,
    link:"/cto"
},
{
    title: "Custom Software Development",
    description: "Discover our dedicated approach to software solutions.",
    icon: CodeBracketIcon,
    link:"/softwaredevelopment"
},
{
    title: "Data & Analytics",
    description: "Tailored solutions for your data needs.",
    icon: CloudArrowDownIcon,
    link:"/dataanalytics"
},
{
    title: "AWS Managed Cloud Service",
    description: "Achieve your goals with our AWS expertise.",
    icon: CloudIcon,
    link:"/aws"
},
{
    title: "Managed DevOps",
    description: "Get in touch for DevOps support and inquiries.",
    icon: ServerIcon,
    link:"/devops"
   
}
 
 
 
];
 
//List of items in Product Menu
 const navListProducts = [
  {
    title:"Cloud Security Monitoring",
    description: "Vigilant Cloud Security Monitoring for Unmatched Protection.",
    icon:  ShieldCheckIcon,
    link:"/products"
  }
 
 ];
 
 
 
// Services menu function
function Services() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);
  const renderItems = navListMenuItems.map(
    ({ link, icon, title, description }, key) => (
      <a href="#" key={key} className="no-underline">
        <MenuItem
          className={`cursor-pointer flex items-center gap-3 rounded-lg ${
            isMenuOpen || isMobileMenuOpen ? "bg-black-50" : ""
          } hover:bg-red-500`}
          onClick={() => {
            setIsMenuOpen(false); // Close desktop menu
            setIsMobileMenuOpen(false); // Close mobile menu          
          }}
        >
          <div className="flex items-center justify-center rounded-lg !bg-black-50 p-2 ">
            {React.createElement(icon, {
              strokeWidth: 2,
              className: "h-6 text-gray-900 w-6",
            })}
          </div>
          <div>
            <Typography
              variant="h6"
              color="black"
              className="flex items-center text-sm font-bold no-underline hover:text-red-500"
              as={Link}
              to={link}
              style={{ fontFamily: 'Poppins, sans-serif' }}
              
            >
              {title}
            </Typography>
            <Typography
              variant="paragraph"
              className="text-xs !font-medium no-underline "
              color="black"
              as={Link}
              to={link}
               style={{ fontFamily: 'Poppins, sans-serif' }}
            >
              {description}
            </Typography>
          </div>
        </MenuItem>
      </a>
    )
  );
 
  return (
    <React.Fragment>
      <Menu
        open={isMenuOpen}
        handler={setIsMenuOpen}
        offset={{ mainAxis: 20 }}
        placement="bottom"
        allowHover={true}
      >
        <MenuHandler>
          <Typography as="div" variant="small" className="font-medium "  style={{ fontFamily: 'Poppins, sans-serif' }}>
            <ListItem
              className={`flex items-center gap-2 py-2 pr-4 font-bold text-white hover:text-red-500 ${
                isMenuOpen || isMobileMenuOpen ? "bg-black-50" : ""
              }`}
              selected={isMenuOpen || isMobileMenuOpen}
              onClick={() => setIsMobileMenuOpen((cur) => !cur)}
            >
              Services
              <ChevronDownIcon
                strokeWidth={2.5}
                className={`hidden h-3 w-3 transition-transform lg:block ${
                  isMenuOpen ? "rotate-180" : ""
                }`}
              />
              <ChevronDownIcon
                strokeWidth={2.5}
                className={`block h-3 w-3 transition-transform lg:hidden ${
                  isMobileMenuOpen ? "rotate-180" : ""
                }`}
              />
            </ListItem>
          </Typography>
        </MenuHandler>
        <MenuList className="hidden max-w-screen-xl rounded-xl lg:block">
          <ul className="grid grid-cols-3 gap-y-2 outline-none outline-0">
            {renderItems}
          </ul>
        </MenuList>
      </Menu>
      <div className="block lg:hidden bg-white rounded-md">
        <Collapse open={isMobileMenuOpen}>{renderItems}</Collapse>
      </div>
    </React.Fragment>
  );
}
 
 
// Products menu function
function Products() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);
  const renderItems = navListProducts.map(
    ({ link, icon, title, description }, key) => (
      <a href="#" key={key} className="no-underline"
      >
        <MenuItem
          className={`cursor-pointer flex items-center gap-3 rounded-lg ${
            isMenuOpen || isMobileMenuOpen ? "bg-black-50" : ""
          } hover:bg-red-500`}
           onClick={() => {
            setIsMenuOpen(false); // Close desktop menu
            setIsMobileMenuOpen(false); // Close mobile menu          
          }}
        >
          <div className="flex items-center justify-center rounded-lg !bg-black-50 p-2 ">
            {React.createElement(icon, {
              strokeWidth: 2,
              className: "h-6 text-gray-900 w-6",
            })}
          </div>
          <div>
            <Typography
              variant="h6"
              color="black"
              className="flex items-center text-sm font-bold no-underline hover:text-red-500"
              as={Link}
              to={link}
              style={{ fontFamily: 'Poppins, sans-serif' }}
            >
              {title}
            </Typography>
            <Typography
              variant="paragraph"
              className="text-xs !font-medium no-underline "
              color="black"
              as={Link}
              to={link}
              style={{ fontFamily: 'Poppins, sans-serif' }}
            >
              {description}
            </Typography>
          </div>
        </MenuItem>
      </a>
    )
  );
 
  return (
    <React.Fragment>
      <Menu
        open={isMenuOpen}
        handler={setIsMenuOpen}
        offset={{ mainAxis: 20 }}
        placement="bottom"
        allowHover={true}
      >
        <MenuHandler>
          <Typography as="div" variant="small" className="font-medium"  style={{ fontFamily: 'Poppins, sans-serif' }}>
            <ListItem
              className={`flex items-center gap-2 py-2 pr-4 font-bold text-white hover:text-red-500 ${
                isMenuOpen || isMobileMenuOpen ? "bg-black-50" : ""
              }`}
              selected={isMenuOpen || isMobileMenuOpen}
              onClick={() => setIsMobileMenuOpen((cur) => !cur)}
            >
              Products & Services
              <ChevronDownIcon
                strokeWidth={2.5}
                className={`hidden h-3 w-3 transition-transform lg:block ${
                  isMenuOpen ? "rotate-180" : ""
                }`}
              />
              <ChevronDownIcon
                strokeWidth={2.5}
                className={`block h-3 w-3 transition-transform lg:hidden ${
                  isMobileMenuOpen ? "rotate-180" : ""
                }`}
              />
            </ListItem>
          </Typography>
        </MenuHandler>
        <MenuList className="hidden max-w-screen-xl rounded-xl lg:block">
          <ul className="grid grid-cols-3 gap-y-2 outline-none outline-0">
            {renderItems}
          </ul>
        </MenuList>
      </Menu>
      <div className="block lg:hidden bg-white rounded-md">
        <Collapse open={isMobileMenuOpen}>{renderItems}</Collapse>
      </div>
    </React.Fragment>
  );
}
 
function NavList({ closeMobileMenu }) {
  return (
    <List className="mt-4 mb-6 p-0 lg:mt-0 lg:mb-0 lg:flex-row lg:p-1 ">
      <Products/>
      <Services/>
 
     {/* //Careers */}
     
      <Typography
        as={Link}
        to="/Careers"
        variant="small"
        color="white"
        className="font-bold no-underline"
        style={{ fontFamily: 'Poppins, sans-serif' }}
      >
        <ListItem className="flex items-center gap-2 py-2 pr-4 hover:text-red-500"  onClick={closeMobileMenu}>Careers</ListItem>
      </Typography>
     
 
      {/* Contact */}
      <Typography
        as={Link}
        to="/contactus"
        variant="small"
        color="white"
        className="font-bold no-underline"
        style={{ fontFamily: 'Poppins, sans-serif' }} 
      >
        <ListItem className="flex items-center gap-2 py-2 pr-4 hover:text-red-500 ">
          Contact
        </ListItem>
      </Typography>
 
      {/* about */}
      <Typography
        as={Link}
        to="/about"
        variant="small"
        color="white"
        className="font-bold no-underline"
        style={{ fontFamily: 'Poppins, sans-serif' }}
      >
        <ListItem className="flex items-center gap-2 py-2 pr-4 hover:text-red-500">
         About Us
        </ListItem>
      </Typography>
    </List>
  );
}
 
export function NavbarWithMegaMenu() {
  const [openNav, setOpenNav] = React.useState(false);
  const closeMobileMenu = () => {
  setOpenNav(false);
  };
 
  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false),
    );
  }, []);
 
  return (
 
    <Navbar className="mx-auto max-w-full px-3 py-2 bg-transparent position-fixed" style={{ zIndex: 100,borderRadius:'0px', backdropFilter: "blur(40px)" }}>
  <div className="w-full flex items-center justify-between text-white-950">
    <Link as={Link} to='/'>
      <img
        style={{ backgroundSize: 'contain' }}
        src={logo}
        height="30"
        className="cursor-pointer mr-4 py-1.5 lg:ml-2 h-12 object-contain"
      />
    </Link>
 
    <div className="hidden lg:block">
      <NavList  closeMobileMenu={closeMobileMenu} />
    </div>
 
    <IconButton
      variant="text"
      className="lg:hidden"
      onClick={() => {
      setOpenNav(!openNav);
      // Close the mobile menu when an item is clicked
      if (openNav) {
        setOpenNav(false);
    }
  }}
    >
      {openNav ? (
        <XMarkIcon className="h-9 w-9 " strokeWidth={2} />
      ) : (
        <Bars3Icon className="h-10 w-10 " strokeWidth={2} />
      )}
    </IconButton>
  </div>
  <Collapse open={openNav} onClick={closeMobileMenu}>
    <NavList />
  </Collapse>
</Navbar>
 
   
  );
}


