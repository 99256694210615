import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from './Components/common/footer/footer';
import CareersPage from './Components/careers/careers';
import { Navigate, Route, Routes } from 'react-router-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import MainLayout from './Components/mainLayout/mainLayout';
import { NavbarWithMegaMenu } from './Components/common/header/NavListMenu';
import About from './Components/about/about'
import './App.css'
import Contact from './Components/contact/contact';
import Services from './Components/services/services';
import Products from './Components/products/products';
import CtoService from './Components/services/ctoService';
import DevopsService from './Components/services/devopsService';
import SoftwareDevelopment from './Components/services/softwareDevelopment';
import DataAnalytics from './Components/services/dataAnalytics';
import AwsManagedService from './Components/services/awsManagedService';
import PrivacyPolicy from './Components/help_links/privacypolicy';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React, { useState, useEffect } from 'react';
import LoadingScreen from './Components/loader/loader';
import Terms from './Components/help_links/terms';
import Faq from './Components/help_links/faq';
function App() {
  const [isLoading, setIsLoading] = useState(true);
 
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2500);
  }, []);
  return (
    <Router>
      {isLoading ? (
        <LoadingScreen/> // Display LoadingScreen while loading
      ) : (
      <div className="App">
      
        
        <NavbarWithMegaMenu/>
        <ToastContainer/>
        <Routes>
          <Route path='/' element={<MainLayout/>} />
          <Route path='/careers' element={<CareersPage />} />
          <Route path='/contactus' element={<Contact/>} />
          <Route path='/about' element={<About/>} />
          <Route path='/services' element={<Services/>} />
          <Route path='/cto' element={<CtoService/>} />
          <Route path='/softwaredevelopment' element={<SoftwareDevelopment/>} />
          <Route path='/dataanalytics' element={<DataAnalytics/>} />
          <Route path='/aws' element={<AwsManagedService/>} />
          <Route path='/devops' element={<DevopsService/>} />
          <Route path='/products' element={<Products/>} />
          <Route path='/privacy' element={<PrivacyPolicy/>} />
          <Route path='/terms' element={<Terms/>} />
          <Route path='/faq' element={<Faq/>} />
          <Route path='*' element={<Navigate to='/' replace />} />
        </Routes>
 
        <Footer />
      </div>)}
    </Router>
     
  );
}
 
export default App;
