import React , { useState,useEffect }  from 'react'
import "./faq.css"

export default function Faq() {
      const [selectedItem, setSelectedItem] = useState(null);

    const handleItemClick = (index) => {
        setSelectedItem(selectedItem === index ? null : index);
    };
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return (
        <div className="faq-container">
            <div className="faq-content">
                <h2 className="faq-heading">Frequently Asked Questions</h2>

                <div className="faq-section">
                    <div className="faq-item" key={1}>
                        <div className="faq-question" onClick={() => handleItemClick(1)}>
                            <h3 className="faq-question-heading">What services does the company provide?</h3>
                            {selectedItem === 1 ? <span>&#9660;</span> : <span>&#9654;</span>}
                        </div>
                        {selectedItem === 1 && (
                            <div className="faq-answer">
                                <p>
                                    The company provides a range of services including technology expertise in areas
                                    such as AI, Data, Cloud, and more. We aim to assist businesses with IT challenges
                                    by offering solutions tailored to the new technology emerging market.
                                </p>
                            </div>
                        )}
                    </div>
                    <div className="separator"></div>

                    <div className="faq-item" key={2}>
                        <div className="faq-question" onClick={() => handleItemClick(2)}>
                            <h3 className="faq-question-heading">How can I contact the company?</h3>
                            {selectedItem === 2 ? <span>&#9660;</span> : <span>&#9654;</span>}
                        </div>
                        {selectedItem === 2 && (
                            <div className="faq-answer">
                                <p>
                                    You can contact us through our email at{' '}
                                    <a href="mailto:info@comptivia.com">info@comptivia.com</a>. Our team is ready to
                                    assist you with any inquiries or concerns you may have.
                                </p>
                            </div>
                        )}
                    </div>
                    <div className="separator"></div>

                    <div className="faq-item" key={3}>
                        <div className="faq-question" onClick={() => handleItemClick(3)}>
                            <h3 className="faq-question-heading">How updated are your employees with industry trends?</h3>
                            {selectedItem === 3 ? <span>&#9660;</span> : <span>&#9654;</span>}
                        </div>
                        {selectedItem === 3 && (
                            <div className="faq-answer">
                                <p>
                                    Our employees undergo continuous training and stay updated with the latest industry
                                    trends. We are committed to ensuring our team has the skills needed to provide you
                                    with cutting-edge technology solutions.
                                </p>
                            </div>
                        )}
                    </div>
                    <div className="separator"></div>

                    <div className="faq-item" key={4}>
                        <div className="faq-question" onClick={() => handleItemClick(4)}>
                            <h3 className="faq-question-heading">What is the company's commitment to clients?</h3>
                            {selectedItem === 4 ? <span>&#9660;</span> : <span>&#9654;</span>}
                        </div>
                        {selectedItem === 4 && (
                            <div className="faq-answer">
                                <p>
                                    Our commitment is to provide clients with top-notch technology solutions, ensuring
                                    their business needs are met. We prioritize customer satisfaction and aim to build
                                    lasting relationships based on trust and reliability.
                                </p>
                            </div>
                        )}
                    </div>
                    <div className="separator"></div>

                    <div className="faq-item" key={5}>
                        <div className="faq-question" onClick={() => handleItemClick(5)}>
                            <h3 className="faq-question-heading">What security measures does Comptivia have in place to protect my data?</h3>
                            {selectedItem === 5 ? <span>&#9660;</span> : <span>&#9654;</span>}
                        </div>
                        {selectedItem === 5 && (
                            <div className="faq-answer">
                                <p>
                                   At Comptivia, we prioritize the security of your data. We employ industry-standard encryption protocols and robust security measures to safeguard your information from unauthorized access, alteration, disclosure, or destruction. Our dedicated security team continuously monitors and updates our systems to ensure the highest level of data protection.
                                </p>
                            </div>
                        )}
                    </div>
                    <div className="separator"></div>

                    <div className="faq-item" key={6}>
                        <div className="faq-question" onClick={() => handleItemClick(6)}>
                            <h3 className="faq-question-heading">Does the company offer internships or job opportunities?</h3>
                            {selectedItem === 6 ? <span>&#9660;</span> : <span>&#9654;</span>}
                        </div>
                        {selectedItem === 6 && (
                            <div className="faq-answer">
                                <p>
                                    Yes, we offer internships and job opportunities. You can check our{' '}
                                    <a href="/careers">Careers Section</a> for current openings and application details.
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};