import React,{useEffect}from "react";
import "./services.css";
import cto from "../../Assets/cto.svg"
import custom from "../../Assets/custom.svg"
import cloud from "../../Assets/cloud.svg"
import devops from "../../Assets/devops.svg"
import data from "../../Assets/datanaly.svg"

function Services() {
  // Scroll to the top of the component when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);

  }, []);

    const cardServiceData = [
        {
          title: 'CTO as a Service',
          content:
            'Our CTO as a service helps startups when they have an idea for product but don’t have enough technical expertise to choose  the right technologies and a team.',
            icon:cto
        },
        {
            title: 'Custom Software Development',
            content:
              'Step into the future with bespoke software solutions crafted uniquely for your business needs, ensuring seamless integration, efficient operations, and sustainable growth.',
              icon:custom
            },
          {
            title: 'Data & Analytics',
            content:
              'Our best-of-breed data and analytical services help our customers to transform raw data to meaningful insights to drive strategy, achieve competitive advantage and business performance.',
              icon:data
            },
          {
            title: 'AWS Managed Cloud Service',
            content:
              'A comprehensive managed solution designed to handle the complexities of your AWS Infrastructure, so you can focus on what matters more',
              icon:cloud
            },
          {
            title: 'Managed DevOps',
            content:
              'Integrate, automate and innovate with our Managed DevOps Service. We make the bridge between development and operations seamless, ensuring faster deployments, reduced errors, & improved code quality. ',
              icon:devops
            },
      
      ];


    const CardService = ({ title, content,icon }) => {      
        return (
          <div className="cardService w-75">
            <div className="content">
            <img src={icon} alt="cto" className="iconServices" />
              <h3>{title}</h3>
              <p className="pb-4" style={{fontSize:'18px',fontWeight:'400'}}>{content}</p>
              {/* <a href="/">Read More</a> */}
            </div>
          </div>
        );
      };
      
  return (
    <div className="container-service">
       <h1 className="hr-lines service-hr" ><span className="text-white">Core</span> <span id="span">Services</span> </h1>
    <div className="containerService serviceBody w-100 ">
      {/* <h1 className="hr-lines"><span className="text-white">Core</span> <span id="span">Services</span> </h1> */}
     
      

      {cardServiceData.map((card, index) => (
        <CardService key={index} title={card.title} content={card.content} icon={card.icon} />
      ))}
    
    </div>
    </div>
  );
}

export default Services;
