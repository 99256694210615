
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faShoppingCart,  faCheck, faShield, faCloudUploadAlt, faMoneyBill1Wave, faTachometerAlt, faProjectDiagram, faDesktop } from '@fortawesome/free-solid-svg-icons';
import React,{useEffect}from 'react'
import './services.css'
import clouds from "../../Assets/cloudService.png"
const AwsManagedService=()=> {

    useEffect(() => {
        window.scrollTo(0, 0);
    
      }, []);
    return (
        <div style={{ marginTop: '100px' }} className='our-service'>
            <div className="container-fluid ">
                <div className="container py-5">
                <h1 className=" text-uppercase our-service-heading">AWS Managed Cloud Service</h1>
                    <div className="row g-5 mb-5 mt-3">
                        <div className="col-xl-6">
                            <div className="row g-4">
                                <div className="col-12">
                                    <img src={clouds} className="img-fluid pb-3 wow zoomIn" data-wow-delay="0.1s" alt="" />
                                </div>
                            </div>

                           
                        </div>
                        <div className="col-xl-6 wow fadeIn" data-wow-delay="0.5s">
                            <h4 className='our-service-subheading'>What is AWS Managed Cloud Service ?</h4>
                            <p className="pb-4">A comprehensive managed solution designed to handle the complexities of your AWS Infrastructure, so you can focus on whatmatters more</p>
                            <div className="row g-4 mb-4">
                                <div className="sectioner-header text-center">
                                    <h3 className='our-service-subheading'>Features and Offerings</h3>
                                </div>
                                <div className="col-md-6">
                                    <div className="ps-3 d-flex align-items-center justify-content-start">
                                        <span className="">
                                            <FontAwesomeIcon icon={faDesktop} size='3x' className='our-service-icon'/>
                                        </span>
                                        <div className="ms-4">
                                            <hh6 className='our-service-subheading'>24/7 Infrastructure Monitoring</hh6>
                                            <p>Our team continuously monitors your AWS environment to ensure uptime and performance</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="ps-3 d-flex align-items-center justify-content-start">
                                        <span className="">
                                            <FontAwesomeIcon icon={faShield} size='3x' className='our-service-icon' />
                                        </span>
                                        <div className="ms-4">
                                            <h6 className='our-service-subheading'>Security</h6>
                                            <p>Rely on our advanced security protocols, including vulnerability scans, threat detection, and more to keep your data safe</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row g-4 mb-4">
                                <div className="col-md-6">
                                    <div className="ps-3 d-flex align-items-center justify-content-start">
                                        <span className="">
                                            <FontAwesomeIcon icon={faCloudUploadAlt} size='3x' className='our-service-icon'/>
                                        </span>
                                        <div className="ms-4">
                                            <h6 className='our-service-subheading'>Automated Backups</h6>
                                            <p>Defining a startup’s IT landscape including infrastructure, security & application development etc.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="ps-3 d-flex align-items-center justify-content-start">
                                        <span className="">
                                            <FontAwesomeIcon icon={faMoneyBill1Wave} size='3x' className='our-service-icon'/>
                                        </span>
                                        <div className="ms-4">
                                            <h6 className='our-service-subheading'>Cost Management</h6>
                                            <p>We help optimize your AWS costs, ensuring you pay only for what you use</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row g-4 mb-4">
                                <div className="col-md-6">
                                    <div className="ps-3 d-flex align-items-center justify-content-start">
                                        <span className="">
                                            <FontAwesomeIcon icon={faTachometerAlt} size='3x' className='our-service-icon'/>
                                        </span>
                                        <div className="ms-4">
                                            <h6 className='our-service-subheading'>Performance Optimization</h6>
                                            <p>We fine tune your AWS services to ensure you re getting the fastest speeds and best performance</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="ps-3 d-flex align-items-center justify-content-start">
                                        <span className="">
                                            <FontAwesomeIcon icon={faProjectDiagram} size='3x' className='our-service-icon'/>
                                        </span>
                                        <div className="ms-4">
                                            <h6 className='our-service-subheading'>Architectural Guidance</h6>
                                            <p>Monthly free Virtual Solutions Architect (VSA) Hours for architectural guidance, reviews and queries</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
 <div className="row g-2 mt-5">
                            <div className="sectioner-header text-center">
                                    <h3 className='our-service-subheading'>Benefits</h3>
                                </div>
                                <div className="col-md-4">
                                    <p className="mb-2"><FontAwesomeIcon icon={faCheck} className='our-service-icon px-4' />Peace of Mind </p>
                                </div>
                                <div className="col-md-4">
                                    <p className="mb-2"><FontAwesomeIcon icon={faCheck} className='our-service-icon px-4' />Expert Support</p>
                                </div>
                                <div className="col-md-4">
                                    <p className="mb-2"><FontAwesomeIcon icon={faCheck} className='our-service-icon px-4' />Scalability</p>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default AwsManagedService
