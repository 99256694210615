
// import Second_section from "../deliveryModel/deliveryModel"
import Home from "../home/home"
import Contact from "../contact/contact";
import DeliveryModel from "../deliveryModel/deliveryModel";
import React, { useState, useEffect } from 'react';


const MainLayout =()=>{
    useEffect(() => {
        window.scrollTo(0, 0);
    
      }, []);
    return(
        <div>
        <Home />
        <DeliveryModel/>
        <Contact/>
        </div>
    )
}

export default MainLayout;