import React from "react";
import "./footer.css";
import { Link } from 'react-router-dom';
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { Image } from "react-bootstrap";
import mail from "../../../Assets/mail.svg";
import facebook from "../../../Assets/facebook.svg";
import phone from "../../../Assets/phone.svg";
import linkedin from "../../../Assets/linkedin.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import njstart from "../../../Assets/njstart-logo 1.svg"
import sbe from "../../../Assets/sbe-stamp 1.svg"
import port from "../../../Assets/Port-Authority-Logo_4C 1.svg"
import nmsdc from "../../../Assets/NMSDC-Logo..svg"
import njpcc from "../../../Assets/NJPCC-logo.svg"
import databricks from "../../../Assets/databricks.svg"
import aws from "../../../Assets/Amazon_Web_Services_logo.svg"
import {
  faFacebookF,
  faTwitter,
  faInstagram,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
import {
  faMapMarkerAlt,
  faPhoneAlt,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";

const Footer = () => {

  const partners = [
    {
      id: 1,
      image: njstart,

    },
    {
      id: 2,
      image: port,

    },
    {
      id: 6,
      image: sbe,


    },
    {
      id: 4,
      image: nmsdc,

    },
    {
      id: 5,
      image: njpcc,

    },
    {
      id: 6,
      image: databricks,

    },
    {
      id: 7,
      image: aws,

    },
  ];
  return (


    <div className="position-relative bg-light">
      <div className="container-fluid row-12 flex ">

        <div className="col-12" >

          <div className=' certificate-slides' >
            <div className='certificate-slide-track' >
              {partners.map((partners) => (
                <div key={partners.id} >
                  <div class="certificate-slide">
                    <img src={partners.image} alt="" />
                  </div>
                </div>
              ))}

              {partners.map((partners) => (
                <div key={partners.id} >
                  <div class="certificate-slide">
                    <img src={partners.image} alt="" />
                  </div>
                </div>
              ))}

            </div>
          </div>
        </div>
      </div>

      <div
        className="container-fluid footer wow fadeIn"
        style={{ backgroundColor: "#161617" }}
        data-wow-delay=".3s"
      >

        <div className="bg-light container-fluid px-5">
        </div>

        <div className="px-5 pt-5 pb-4">
          <div className="row g-5">
            <div className="col-lg-3 col-md-6">
              <a href="/">
                <Image
                  src={require("../../../Assets/comptivia.png")}
                  style={{ backgroundSize: 'contain' }}
                  height="30"
                  className="cursor-pointer py-1  h-12 object-contain"

                ></Image>
              </a>
              <p className=" text-light" style={{ fontWeight: '100' }}>
                <i >
                  Engineering Partner for Grateful Outcome
                </i>
              </p>

              <div className="d-flex hightech-link pt-5">
                <a
                  href="#"
                  className="btn-light nav-fill btn btn-square rounded-circle me-2"
                >
                  <FontAwesomeIcon icon={faFacebookF} className="text-primary " />
                </a>
                <a
                  href="#"
                  className="btn-light nav-fill btn btn-square rounded-circle me-2"
                >
                  <FontAwesomeIcon icon={faTwitter} className="text-primary" />
                </a>
                <a
                  href="#"
                  className="btn-light nav-fill btn btn-square rounded-circle me-2"
                >
                  <FontAwesomeIcon icon={faInstagram} className="text-primary" />
                </a>
                <a
                  href="#"
                  className="btn-light nav-fill btn btn-square rounded-circle me-0"
                >
                  <FontAwesomeIcon icon={faLinkedinIn} className="text-primary" />
                </a>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <a href="#" className="h3" style={{ color: "#E62536" }}>
                Short Link
              </a>
              <div className="mt-4 d-flex flex-column short-link">
                {/* products  */}
                <a href="/products" className="mb-2 text-white">
                  <FontAwesomeIcon
                    icon={faAngleRight}
                    className="footer-icon me-2"
                  />
                  Products & Services
                </a>
                {/* services  */}
                <a href="/cto" className="mb-2 text-white">
                  <FontAwesomeIcon
                    icon={faAngleRight}
                    className="footer-icon me-2"
                  />
                  Services
                </a>
                {/* Careers  */}
                <a href="/Careers" className="mb-2 text-white">
                  <FontAwesomeIcon
                    icon={faAngleRight}
                    className="footer-icon me-2"
                  />
                  Careers
                </a>
                {/* contact                 */}
                <a href="/contactus" className="mb-2 text-white">
                  <FontAwesomeIcon
                    icon={faAngleRight}
                    className="footer-icon me-2"
                  />
                  Contact
                </a>
                {/* About  */}
                <a href="/about" className="mb-2 text-white">
                  <FontAwesomeIcon
                    icon={faAngleRight}
                    className="footer-icon me-2"
                  />
                  About Us
                </a>

              </div>
            </div>


            <div className="col-lg-3 col-md-6">
              <a href="#" className="h3" style={{ color: "#E62536" }}>
                Help Link
              </a>

              <div className="mt-4 d-flex flex-column help-link">

                <Link to="/terms" className="mb-2 text-white">
                  {/* Assuming you're using FontAwesome */}
                  <FontAwesomeIcon icon={faAngleRight} className="footer-icon me-2" />
                  Terms Of Use
                </Link>
                <Link to="/privacy" className="mb-2 text-white">
                  <FontAwesomeIcon icon={faAngleRight} className="footer-icon me-2" />
                  Privacy Policy
                </Link>

                {/* FAQs Link */}
                <Link to="/faq" className="mb-2 text-white">
                  <FontAwesomeIcon icon={faAngleRight} className="footer-icon me-2" />
                  FAQs
                </Link>

              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <a href="#" className="h3" style={{ color: "#E62536" }}>
                Contact Us
              </a>

              <div className="text-white mt-4 d-flex flex-column contact-link">
                <a
                  href="#"
                  className="pb-3 text-light border-bottom border-primary"
                >
                  <FontAwesomeIcon
                    icon={faMapMarkerAlt}
                    className="footer-icon me-2"
                  />
                  <span>161 Main Rd Montville, NJ, USA 07045</span>
                </a>
                <a
                  href="#"
                  className="py-3 text-light border-bottom border-primary"
                >
                  <FontAwesomeIcon
                    icon={faPhoneAlt}
                    className="footer-icon me-2"
                  />
                  +1 973 944 1209
                </a>
                <a
                  href="#"
                  className="py-3 text-light border-bottom border-primary"
                >
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    className="footer-icon me-2"
                  />
                  info@comptivia.com
                </a>
              </div>
            </div>
          </div>
          <hr className="text-light mt-5 mb-4" />
          <div className="row">
            <div className="col-md-6 text-center text-md-start">
              <span className="text-light">
                <a href="#" className="text-secondary">
                  {" "}
                </a>
                <p>&copy; 2024 Comptivia.com | All rights reserved.</p>
              </span>
            </div>

            <div class="col-md-6 text-center text-md-end">
              <span class="text-light">
                <a href="/privacy" className="text-secondary">
                  Privacy Policy | Cookie Policy
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default Footer;
