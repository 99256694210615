import React, { useEffect } from "react";
import "./products.css";
import multicloud from "../../Assets/multi cloud support.svg";
import driftdetection from "../../Assets/drift detection.svg";
import robustalerting from "../../Assets/robust.svg";
import advancedcspm from "../../Assets/advanced cspm.svg";
import continuousmonitoring from "../../Assets/continous monitoring.svg";
import userfriendlydashboard from "../../Assets/user friendly.svg";

const ServiceItem = ({ icon, title, content }) => (
  <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
    <div className="product-item position-relative h-100">
      <div className="product-text rounded p-5">
        <div className="btn-square bg-light rounded-circle mx-auto mb-4" style={{ width: '64px', height: '64px' }}>
          <img className="img-fluid" src={icon} alt="Icon" />
        </div>
        <h5 className="mb-3 text-dark">{title}</h5>
        <p className="mb-0 text-dark">{content}</p>
      </div>
    </div>
  </div>
);

function Products() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
const servicesData = [
  {
    title: "Multi Cloud Support ",
    content:
      "Integrating with Amazon Web Service, Azure, and GCP. Offering unified security management across all platforms.",
    icon: multicloud,
  },
  {
    title: "Drift Detection Solution",
    content:
      "Detecting and rectifying configuration changes to avoid potential security vulnerabilities while building a website.",
    icon: driftdetection,
  },
  {
    title: "Robust Alerting          ",
    content:
      "Providing instant alerts on security incidents. Generating comprehensive reports for informed decision making.",
    icon: robustalerting,
  },
  {
    title: "Advanced CSPM Service",
    content:
      "Ensuring security compliance through advanced Cloud Security Posture Management. Identifying and mitigating risks swiftly.",
    icon: advancedcspm,
  },
  {
    title: "Continuous Monitoring Solution",
    content:
      "Ensuring 24/7 monitoring of your cloud environment. Detecting anomalies and potential threats in real-time vigilantly and proactively.",
    icon: continuousmonitoring,
  },
  {
    title: "User-Friendly Dashboard Design",
    content:
      "Designing an intuitive interface for easy navigation. Ensuring quick access to key security insights, enhancing the overall user experience.",
    icon: userfriendlydashboard,
  },
];

  return (
    <div className="container-product">
      <div className="content-container">
        <div className="text-container">
          <h2 className="py-5 fontChange" style={{ color: "#FFFFFF" }}>
            Build Cloud Agnostic Products and Platforms for grateful outcomes
          </h2>
          <p
            style={{
              fontSize: "18px",
              color: "white",
              fontWeight: "300",
              maxWidth: "500px",
            }}
          >
            <i>
              {" "}
              Accelerate Products and Platform delivery at which you create
              grateful business value. Our Engineering Team consistently
              validates and optimizes products, provides end to end-life
              engineering services.
            </i>
          </p>
        </div>
        <div className="image-container">
          <img
            src={require("../../Assets/image1.png")}
            className="image"
            alt=""
          />
        </div>
      </div>
      <div className="container-cloud-security">
        <div className="cloudContainer">
          <h1 className="hr-lines-product mt-4">
            Cloud Security<span id="span"> Monitoring</span>{" "}
          </h1>
          <h5 style={{}} className="sub-heading">
            <i>Secure your cloud with comptivia's comprehensive monitoring</i>
          </h5>
          <p className="cloudContainer-content" style={{ fontSize: "18px" }}>
            comptivia empowers businesses by enhancing the security of AWS,
            Azure, and GCP, the three major cloud platforms, through cutting
            edge Cloud Security Posture Management (CSPM), drift detection, and
            continuous monitoring
          </p>
        </div>

        <div className="py-5">
          <div className="row g-4 px-4 py-5 mx-5 my-5">
            {servicesData.map((service, index) => (
              <ServiceItem key={index} {...service} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Products;
